import React from "react";
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import BackgroundImage from '../components/BackgroundImage';
import TipList from '../components/tipList';
import ShareButtons from '../components/ShareButtons';
import reactToString from 'react-to-string';

const options = {
  renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
};


class TipTemplate extends React.Component {

   truncate(str) {
      return str.length > 10 ? str.substring(0, 100) + "..." : str;
    }

  render(props) {
    const post = get(this.props, 'data.contentfulTip');
    const postContent = documentToReactComponents(post.body.json, options);
    const postAuthor = get(this.props, 'data.contentfulTip.author');
    const authorBio = documentToReactComponents(postAuthor.bio.json, options);
    const related = get(this, 'props.data.related.edges');

    const url = typeof window !== 'undefined' ? window.location.href : '';
    const tipDescription = this.truncate(reactToString(postContent));
    

    return (
      <Layout>
        <div>
            <SEO title={`Tip: ${post.title}`} image={post.image.file.url} description={tipDescription} />
            
            <Wrapper>

            <BackgroundImage imageData={post.image.fluid}>
              <Overlay>
                  <Category>{post.category.name}</Category>
                  <Heading>{post.title}</Heading>
                </Overlay>
            </BackgroundImage>
          
            </Wrapper>

            <Main>


              <Bodytext>
                <Tipheader>
                  <p>
                    <ReadTime><i className="far fa-clock"></i> {post.timeToRead}</ReadTime>
                    <Created>{post.createdAt}</Created>
                  </p>
                  <ShareButtons url={url} title={post.title} description={tipDescription} />
                </Tipheader>

                {postContent}

              </Bodytext>

              <h2 className="author-title">Meet the author</h2>
              
              <Author>

                <img src={postAuthor.photo.file.url} alt={postAuthor.name} style={{ width: `25%`, height: `auto`, marginBottom: `0px`}}/>
                
                <AuthorInfo>
                   <p className="name">Tip by {postAuthor.name}</p>
                   <div className="bio">{authorBio}</div> 
                   <a className="view-link" href={"/author/" + postAuthor.slug}>Find out more</a>
                </AuthorInfo>

              </Author>

            </Main>

            <TipList data={related} tipHeight="400px" headingText="Other tips you might like" itemWidth="30%" headingClass="uppercase"/>

          </div>

      </Layout>
    ) 
  }
}

export default TipTemplate

export const PageQuery = graphql`
  query PageBySlug($slug: String!, $category: String, $title: String) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulTip(slug: { eq: $slug }) {
        title
        slug
        timeToRead
        tag
        category {
           name
        }
        createdAt(formatString: "dddd Do, MMMM YYYY")
        body {
          json
        }
        image {
           file {
            url
          }
          fluid(maxWidth: 1400, maxHeight: 900, resizingBehavior: SCALE) {
            ...GatsbyContentfulFluid
          }
        }
        author {
          name
          instagram
          email
          slug
          bio {
            json
          }
          photo {
            file {
              url
            }
          } 
        }
      }

      related: allContentfulTip(filter: { category: {name: {eq: $category}}, title: {ne: $title}  }, limit: 3) {
        edges {
          node {
            title
            slug
            timeToRead
            tag
            category {
                name
            }
            image {
              file {
                url
              }
              fluid(maxWidth: 1400, maxHeight: 900, resizingBehavior: SCALE) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `;

const Wrapper = styled.div`
  margin: 0 10px;
`;

const Category = styled.small`
  color: white;
  letter-spacing: 0.25em;
  font-size: 90%;
`;

const Heading = styled.h1`
  font-size: 40px;
  align-self: flex-start;
`;

const Overlay = styled.div`
  width: 800px;
  margin: 0 auto;
  height: 100%;
  vertical-align: left;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media(max-width: 768px) {
    padding: 0 20px;
  }
`;

const Tipheader = styled.div`
  letter-spacing: 2px;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  @media(max-width: 650px) {
    flex-direction: column;
  }

  i {
    line-height: 1.9em;
    margin-right: 10px;
    color: #3c7db6;
  }

  p {
    margin-bottom: 5px;
  }

  .post-meta-share-icons {
    display: flex;
    justify-self: flex-end;
    align-self: center;

    @media(max-width: 650px) {
     align-self: flex-start;
     margin-top: 10px;
    }

    button {
      margin: 0 5px;
    }
  }
`;

const Created = styled.span`

`;

const ReadTime = styled.span`
  display: flex;
`;

const Bodytext = styled.div`
  color: #fff;

  a {
    color: #fff;

    &:hover {
      color: #3c7db6;
    }
  }
`;

const Main = styled.div`
  max-width: 700px;
  margin: 60px auto; 
  display: flex;
  flex-direction: column;

  @media(max-width: 768px) {
    padding: 0 20px;
  }


  .author-title {
    color: #fff;
    margin: 20px 10px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 25px;

    &:before,
    &:after {
      content: '';
      border: 1px solid #3c7db6;
      display: inline-block;
      width: 50px;
      margin-right: 10px;
    }

    &:after {
      margin-right: 0;
      margin-left: 10px;
    }
  }
`;

const Author = styled.div`
  color: #fff;
  align-items: flex-start;
  display: flex; 
  flex-direction: row;
  font-size: 15px;
  
  .name {
      color: #fff;
      margin-bottom: 5px;
  }

  .bio p {
    margin-bottom: 15px;
  }
  
  
  .btn {
    background: #3c7db6;
    border: 1px solid #3c7db6;
    padding: 10px 15px;
    font-size: 16px;
    color: #fff;
    margin-top: 20px;
    
    &:hover {
        background: transparent;
        color: #3c7db6;
    }
  }
`;

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 20px;

  a {
    color: #3c7db6;
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }
`;
