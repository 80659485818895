import React from "react";
import styled from 'styled-components';
import { Facebook, Twitter, Linkedin, Mail } from 'react-feather';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

const ShareButtons = ({ url, title, description }) => (
  <SocialShareButtons className="post-meta-share-icons">
    <span>Share:</span>
    <FacebookShareButton url={url} quote={description}>
      <Facebook strokeWidth={1.25} />
    </FacebookShareButton>

    <LinkedinShareButton url={url} title={title} summary={description}>
      <Linkedin strokeWidth={1.25} />
    </LinkedinShareButton>

    <TwitterShareButton url={url} title={description}>
      <Twitter strokeWidth={1.25} />
    </TwitterShareButton>

    <EmailShareButton url={url} title={description}>
      <Mail strokeWidth={1.25} />
    </EmailShareButton>
  </SocialShareButtons>
);

export default ShareButtons;

const SocialShareButtons = styled.div`
  button svg {
    transition: all 0.25s;

    &:hover {
      color: #3c7db6;
    }
  }
`;