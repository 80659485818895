import React from "react"
import styled from 'styled-components'
import get from 'lodash/get'
import TipTeaser from '../components/TipTeaser'

class tipList extends React.Component {
    render(props) {
        const tips = get(this.props, 'data');
        const headingText = get(this.props, 'headingText');
        const headingClass = get(this.props, 'headingClass');
        const tipHeight = get(this.props, 'tipHeight');
        const itemWidth = get(this.props, 'itemWidth');

        return (

            <Container>

                <h2 className={headingClass}>{headingText}</h2>

                <TeaserList>
                    {tips.map(({ node }) => {
                        return (
                            <TeaserListItem key={node.slug} style={{ flex: `1 0 ${itemWidth}`}}>
                                <TipTeaser height={tipHeight} tip={node} />
                            </TeaserListItem>
                        )
                    })}
                </TeaserList>

            </Container>

        )
    }
}

export default tipList

const Container = styled.div`
  h2.uppercase {
    color: #fff;
    margin: 20px 10px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 25px;

    &:before,
    &:after {
      content: '';
      border: 1px solid #3c7db6;
      display: inline-block;
      width: 50px;
      margin-right: 10px;
    }

    &:after {
      margin-right: 0;
      margin-left: 10px;
    }
  }
`;

const TeaserList = styled.ul`
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media(max-width: 500px) {
    flex-direction: column;
  }
`;

const TeaserListItem = styled.li`
  list-style: none;
  margin: 10px; 
`;